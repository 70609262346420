:root {
  --lightgray: #f0f0f3;
  --black: #262626;
  --blue: #14a6f4;
  --gray: #969696;
  --grey: #969696;
  --lightgrey: #f0f0f3;
  --blue-darker: #0c7db9;
  --error: #ff7f50;
  --quartz: #ff4d6a;
  --darkmode: #0a1635;
  --darkmode-deep: #040e26;
  --white: #f6f6f8;
  --bgtext: #444;
  --blueShadow: rgba(2, 173, 231, 0.4);
  --tags: #6f7889;
  --darkgray: #333;
  --blue-pale: #88b6d0;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;900&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e293b;
  /* background: linear-gradient(179deg, rgb(4 14 38) 0%, rgb(15 27 56) 45%, rgb(14 26 56) 100%); */
  color: var(--bgtext);
  /* overflow: hidden; */
}

.text-pink-unique {
  color: var(--quartz);
}

.text-blue-unique {
  color: var(--blue);
}
.bg-blue-unique {
  background-color: var(--blue);
}
button.bg-blue-unique:hover {
  background-color: var(--blue-darker);
  transition: all 0.4s ease;
}

.bg-darkmode {
  background-color: var(--darkmode);
}
.bg-darkdeep {
  background-color: var(--darkmode);
}
.z-10000 {
  z-index: 10000;
}

.font-raleway {
  font-family: 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item-selected {
  background: rgba(255,255,255,.15);
  border-radius: 5px;
}

.change-node-text {
  position: absolute;
  font-size: 1.25em;
  color: #e7e7e7;
  z-index: 10;
  top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 15px;
}

.dynamic-svg-wrap > svg {
  width: 100%;
  height: auto;
}

.site-content h1 {
  line-height: 1.15;
}

/* loader */
.loader .container {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url('#goo');
  animation: rotate-move 2s ease-in-out infinite;
}

.loader .dot { 
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader .dot-3 {
  background-color: #14a6f4;
  animation: dot-3-move 2s ease infinite, index 6s ease infinite;
}

.loader .dot-2 {
  background-color: #ff4d6a;
  animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
}

.loader .dot-1 {
  background-color: #f0f0f3;
  animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
}

@keyframes dot-3-move {
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-2-move {
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-1-move {
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes rotate-move {
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
}

@keyframes index {
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
}

.loader_sq {
  display: block;
  animation: rotate 2s infinite;
  height: 50px;
  width: 50px;
}
.loader_sq:before,
.loader_sq:after {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader_sq:before {
  animation: box1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 var(--blue);
  margin-bottom: 10px;
}
.loader_sq:after {
  animation: box2 1s infinite;
  background-color: var(--blue);
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes box1 {
  0% {
    box-shadow: 30px 0 0 var(--blue);
  }
  50% {
    box-shadow: 0 0 0 var(--blue);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--blue);
    margin-bottom: 10px;
  }
}

@keyframes box2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}

.modal-window {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0,0,0,.65);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window.open {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  position: relative;
}
.modal-close {
  color: #aaa;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}
.modal-close svg {
	width: 24px;
	height: 24px;
}
.modal-iframe {
  width: 960px;
  height: 560px;
}
.modal-backdrop {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
  background-color: transparent;
}

.modalpic {
  width: 128px;
  min-width: 128px;
}
.codeblock {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background: rgba(0,0,0,0.25);
  padding: 10px 8px;
  border-radius: 5px;
  margin-top: 5px;
}

.pattern-polka {
  background-color: #e5e5f7;
  opacity: 1;
  background-image: radial-gradient(#3f5881 0.5px, #1e293b 0.5px);
  background-size: 20px 20px;
}

.pattern-polka-dark {
  background-color: #e5e5f7;
  opacity: 1;
  background-image: radial-gradient(#2f4261 0.5px, #0a1635 0.5px);
  background-size: 20px 20px;
}

form input[type="file"] {
  width: 1px;
}
.upload-field {
  border: dashed 2px #f5f5f5
}
.img-prev {
  background-size: contain;
  background-position: center;
  height: 105px;
  width: auto;
  background-repeat: no-repeat;
}
.rti--input {
  color: black;
}
.rti--tag {
  background: var(--darkgray) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rti--tag span {
  line-height: 1.5;
  padding-left: 4px;
}
.rti--tag button {
  color: rgb(245, 138, 138);
  font-size: 0.8rem;
}

/* Multi select input */
.multiselect select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

.multiselect {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  border: 1px solid #393939;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  background: linear-gradient(to bottom, #ffffff 0%, #fff 100%);
}
.multiselect select, .multiselect::after {
  grid-area: select;
}
.multiselect::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: #393939;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.multiselect select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid #101484;
  border-radius: inherit;
}

.multiselect select[multiple] {
  padding-right: 0;
  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 5rem;
}
.multiselect select[multiple] option {
  white-space: normal;
  outline-color: #101484;
}

.select-disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

/* Text Editable Input */
.editable-input {
	display: inline;
	position: relative;
}
.editable-input input {
	width: 0;
	overflow: hidden;
	padding: 0 2px;
	background: transparent;
	border: dashed 1px transparent;
	color: #fff;
	outline: none;
  max-width: 140px;
  text-align: left;
	/* transition: all .15s ease; */
}
.editable-input label {
	display: block;
	opacity: 0;
	background-color: rgba(0,0,0,.3);
	color: #fff;
	position: absolute;
	left: 0;
	top: -16px;
	font-size: .5em;
	padding: 0 4px;
	transition: opacity .15s ease;
}
.editable-input button {
	margin-left: 8px;
  background: #454258;
  color: #fff;
  font-size: .75em;
  cursor: pointer;
  padding: 1px;
  border: solid 1px #454258;
  border-radius: 4px;
}
.editable-input button:hover {
	color: rgba(255, 255, 255, 0.8);
}

.editable-input input:focus {
	width: 320px;
	border-color: rgba(255, 255, 255, 0.3);
}
.editable-input input:focus ~ label {
	opacity: 1;
}
.editable-input input:focus ~ span,
.editable-input input:focus ~ button {
	display: none;
}

/**  CSV Table **/
.csv-table {
  border-spacing: 0 5px;
}

.csv-table tr {
  border-radius: 20px;
}

.csv-table tr td:nth-child(n+2),
.csv-table tr th:nth-child(n+2) {
  border-radius: 0 .625rem .625rem 0;
}

.csv-table tr td:nth-child(1),
.csv-table tr th:nth-child(1) {
  border-radius: .625rem 0 0 .625rem;
}